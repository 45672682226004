import Logo from '@/assets/img/Logo.png';
import styles from './index.module.less';
import { DEMO_VERSION } from '@/config';

interface HeaderProps {
  children?: React.ReactNode;
  hide?: boolean;
}

function Header(props: HeaderProps) {
  const { children, hide } = props;
  return (
    <div
      className={styles.header}
      style={{
        display: hide ? 'none' : 'flex',
      }}
    >
      <div className={styles['header-logo']}>
        <img src={Logo} alt="Logo" />
      </div>

      {children}

      <div className={styles['header-right']}>
        <span className={styles['header-right-text']}>Version:{DEMO_VERSION}</span>
      </div>
    </div>
  );
}

export default Header;
